import axios, { AxiosPromise } from 'axios';

import { IPricesProjection, IPricesProjectionPayload } from '@contexts/prices-projection';
import { IPaginatedResponse } from '@contexts/types';

import config from '../../config';
import { transformObjectKeysToSnakeCase } from '@utils/common';

export const serverApiHost = axios.create({
  baseURL: `${config.SERVER_BASE_URL}/api/prices-projection`,
  withCredentials: true,
});

export function upsertPricesProjection(formData: IPricesProjectionPayload, projectionId?: string): AxiosPromise {
  const preserveKeys = new Set(['i_5', 'i_100']);
  const snakedFormData = transformObjectKeysToSnakeCase(formData, preserveKeys);

  return serverApiHost.post('/create', {
    ...snakedFormData,

    projectionId,
  });
}

export function getPricesProjection(projectionId: string): AxiosPromise {
  return serverApiHost.get(`/get-by-id/${projectionId}`);
}

export function getPartnerPricesProjection(
  retailTraderId: string,
  page?: number,
): AxiosPromise<IPaginatedResponse<IPricesProjection>> {
  return serverApiHost.get(`/get-partner-by-retail-trader-id/${retailTraderId}`, {
    params: { page },
  });
}
