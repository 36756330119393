import React from 'react';
import axios from 'axios';

import usePricesProjections from '@hooks/use-prices-projection';

import { useAuth } from '@src/auth-wrapper';

import { PriceProjectionsHistoryTable } from '@components/organisms/price-projections-history/table';
import { PriceProjectionsHistoryNoDataMessage } from '@components/atoms/price-projections-history/messages';

export function PriceProjectionsHistoryCore() {
  const { user } = useAuth();
  const { pricesProjections, partnerId, setPartnerId } = usePricesProjections();

  const currentDomain = window.location.origin;
  const serverPort = '5000';
  const serverBaseURL = `${currentDomain.replace(/:\d+/, `:${serverPort}`)}/api/auth`;
  const serverApiClient = axios.create({
    baseURL: serverBaseURL,
    withCredentials: true,
  });

  React.useEffect(() => {
    const fetchEnvVariables = async () => {
      try {
        const { data } = await serverApiClient.get('/request-vite-env');
        setPartnerId(data.MAIN_PARTNER_ID);
      } catch {}
    };
    fetchEnvVariables();
  }, []);

  if (partnerId) {
    if (pricesProjections && pricesProjections?.total > 0) {
      return <PriceProjectionsHistoryTable />;
    } else return <PriceProjectionsHistoryNoDataMessage isAdmin={user?.isAdmin} />;
  } else return <></>;
}
