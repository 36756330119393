import React from 'react';

import { UserGroup, UserInfo } from '@contexts/users/types';
import axios from 'axios';

export interface AuthenticationState {
  isAuthenticated: boolean;
  accessToken?: string;
}

export interface IAuthContext {
  authStatus: AuthenticationState;
  setIsPrivateRoute: React.Dispatch<React.SetStateAction<boolean>>;
  user?: UserInfo;
  logout: () => void;
  loading: boolean;
}

export const AuthContext = React.createContext({} as IAuthContext);
export const useAuth = () => React.useContext<IAuthContext>(AuthContext);

interface AuthWrapperProps {
  children: JSX.Element;
}

function AuthWrapper({ children }: AuthWrapperProps) {
  const [user, setUser] = React.useState<UserInfo | undefined>(undefined);
  const [token, setToken] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isPrivateRoute, setIsPrivateRoute] = React.useState(true);

  const currentDomain = window.location.origin;
  const serverPort = '5000';
  const serverBaseURL = `${currentDomain.replace(/:\d+/, `:${serverPort}`)}/api/auth`;
  const serverApiClient = axios.create({
    baseURL: serverBaseURL,
    withCredentials: true,
  });

  React.useEffect(() => {
    const checkSession = async () => {
      try {
        await serverApiClient.get('/');
        const { data } = await serverApiClient.get('/profile');
        const { user, token } = data.userAuthData;

        setUser(parseUserInfo(user));
        setToken(token);
        token && setIsAuthenticated(true);
      } catch (error: Error | any) {
        window.location.href = `${serverBaseURL}/login`;
      } finally {
        setLoading(false);
      }
    };
    !isAuthenticated && isPrivateRoute && checkSession();
  }, [isPrivateRoute, isAuthenticated]);

  const parseUserInfo = (userData: any): UserInfo | undefined => {
    if (!userData) return undefined;
    const userGroups = userData['https://clarke.com.br/groups'] as UserGroup[];
    return {
      id: userData['https://clarke.com.br/uuid'],
      name: userData.name,
      email: userData.email,
      pictureUrl: userData.picture,
      isSuperAdmin: userGroups.includes('Admin Especial de Vendas'),
      isAdmin: userGroups.includes('Vendas Admin'),
      isCommercialDealer: userGroups.includes('Vendas Revendedor'),
      roles: userData['https://clarke.com.br/roles'],
      groups: userGroups,
      phone: userData.phone_number,
    };
  };

  const logout = async () => {
    try {
      await serverApiClient.get('/logout');
      window.location.href = `${serverBaseURL}/login`;
      setUser(undefined);
      setIsAuthenticated(false);
    } catch {
      window.location.reload();
    }
  };

  const contextValue: IAuthContext = {
    authStatus: { isAuthenticated, accessToken: token },
    user,
    logout,
    loading,
    setIsPrivateRoute,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export default AuthWrapper;
