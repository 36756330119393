import config from '@src/config';
import { amplitudeInit, amplitudeTrackEvent } from './amplitude';
import axios from 'axios';
import React from 'react';

export const initEventTrackingSystem = () => {
  return amplitudeInit(true, true);
};

const currentDomain = window.location.origin;
const serverPort = '5000';
const serverBaseURL = `${currentDomain.replace(/:\d+/, `:${serverPort}`)}/api/auth`;
const serverApiClient = axios.create({
  baseURL: serverBaseURL,
  withCredentials: true,
});
export const trackEvent = async (eventLabel: string, eventProperties?: Record<string, string | number>) => {
  const amplitudeInitialized = undefined; //TODO mover para o ssr

  if (amplitudeInitialized) {
    return await amplitudeTrackEvent(eventLabel, eventProperties);
  }
};
