import { ISubmarketPricesProjection } from './types';

export const includeYearPrefix = (submarkets: ISubmarketPricesProjection) => {
  return Object.entries(submarkets).reduce((acc: Record<string, any>, [submerket, idx]) => {
    acc[submerket] = Object.entries(idx).reduce((idxAcc: Record<string, any>, [energyType, years]) => {
      idxAcc[energyType] = Object.fromEntries(
        Object.entries(years as Record<string, any>).map(([year, value]) => [
          year.includes('year') ? year : `year${year}`,
          value,
        ]),
      );
      return idxAcc;
    }, {});
    return acc;
  }, {});
};

export const removeYearPrefix = (submarkets: ISubmarketPricesProjection) => {
  return Object.entries(submarkets).reduce((acc: Record<string, any>, [submerket, idx]) => {
    acc[submerket] = Object.entries(idx).reduce((idxAcc: Record<string, any>, [energyType, years]) => {
      idxAcc[energyType] = Object.fromEntries(
        Object.entries(years as Record<string, any>).map(([year, value]) => [year.replace('year', ''), value]),
      );
      return idxAcc;
    }, {});
    return acc;
  }, {});
};
