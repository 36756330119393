import React, { useState } from 'react';
import { Menu, NavigationLinkProps } from '@clarke-energia/foton';

import { useAuth } from '@src/auth-wrapper';

import { HOME_PATH } from '@routers/constants';

import ClarkeLogo from '@assets/clarke-logo.svg';

import OpenButton from '@components/atoms/sidebar/mobile-open-button';
import SidebarSkeleton from '@components/atoms/sidebar/skeleton';

const SideBar: React.FC = () => {
  const { user, logout, loading } = useAuth();
  const [isMenuCollapsed, setIsMenuCollapsed] = React.useState<boolean>(false);

  if (loading) return <SidebarSkeleton />;

  const navigationLinks: NavigationLinkProps[] = [
    { label: 'Histórico', path: HOME_PATH, iconName: 'RectangleStackIcon' },
  ];

  return (
    <div className="w-fit h-full relative m-6">
      <Menu
        adminLogo={user?.pictureUrl}
        userLogo={user?.pictureUrl}
        name={user?.name}
        isAdmin={user?.isAdmin}
        companyLogo={ClarkeLogo}
        adminLinks={navigationLinks}
        userLinks={navigationLinks}
        logoutAction={logout}
        menuCollapseControl={{
          isMenuCollapsed,
          setIsMenuCollapsed: () => setIsMenuCollapsed((previous) => !previous),
        }}
      />
      {/* <img
        src={CollapsedIcon}
        alt="collapsed icon"
        className={twMerge('absolute top-6 w-6 h-6', isMenuCollapsed ? 'right-6' : 'right-7')}
        onClick={() => setIsMenuCollapsed((previous) => !previous)}
      /> */}
    </div>
  );
};

export const MobileSidebar: React.FC = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState<boolean>(false);
  const closeSidebar = () => setIsMobileSidebarOpen(false);
  const openSidebar = () => setIsMobileSidebarOpen(true);
  return (
    <div className="flex mx-auto">
      <OpenButton openSidebar={openSidebar} />
      {/* 
      <Transition show={isMobileSidebarOpen} as={React.Fragment}>
        <Dialog open={isMobileSidebarOpen} onClose={closeSidebar} className="relative z-50">
          <Transition.Child
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 opacity-75 bg-neutral-60" aria-hidden="true" />
          </Transition.Child>
          <div className="flex fixed inset-y-0 p-l-0 max-w-[75%] w-full">
            <Transition.Child
              as={React.Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="flex relative flex-col flex-1 px-4 space-y-4 bg-green-700">
                <CloseButton closeSideBar={closeSidebar} />
                <img className="w-6 h-6" src={ClarkeLogoWhite} alt="Clarke logo" />
                <MobileNavigationLinks />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition> */}
    </div>
  );
};

export default SideBar;
