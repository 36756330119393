import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';
import { Trader, TraderContextType } from './types';

export const TraderContext = createContext({} as TraderContextType);

const currentDomain = window.location.origin;
const serverPort = '5000';
const serverBaseURL = `${currentDomain.replace(/:\d+/, `:${serverPort}`)}/api/trader`;
const serverApiClient = axios.create({
  baseURL: serverBaseURL,
  withCredentials: true,
});

interface Provider {
  children: React.ReactNode;
}

const TradersProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [traders, setTraders] = useState<Trader[]>([]);
  const [traderById, setTraderById] = useState<Trader | undefined>();
  const [traderId, setTraderId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  async function getTraders() {
    setLoading(true);
    try {
      const response = await serverApiClient.get('/');
      setTraders(response.data);
    } catch (error) {
      setTraders([]);
    }
    setLoading(false);
  }

  async function getTraderById(traderId: string) {
    setLoading(true);
    try {
      const response = await serverApiClient.get(`/${traderId}`);
      setTraderById(response.data);
    } catch (error) {
      alert(`Erro ao buscar trader com ID ${traderId}`);
    }
    setLoading(false);
  }

  async function getTraderForUserHandler(userId: string) {
    setLoading(true);
    try {
      const response = await serverApiClient.get(`/user/${userId}`);
      setTraderById(response.data);
    } catch (error) {
      alert('Erro ao buscar trader do usuário');
    }
    setLoading(false);
  }

  useEffect(() => {
    if (traderId) getTraderById(traderId);
  }, [traderId]);

  return (
    <TraderContext.Provider
      value={{
        getTraders,
        getTraderForUserHandler,
        setTraderId,
        traderById,
        traders,
        loading,
      }}
    >
      {children}
    </TraderContext.Provider>
  );
};

export default TradersProvider;
