import MainRouter from '@routers/main-router';

import AuthWrapper from './auth-wrapper';

function App() {
  return (
    <AuthWrapper>
      <MainRouter />
    </AuthWrapper>
  );
}

export default App;
