import React from 'react';

import { Input } from '@components/atoms/form/input';
import SubmarketFormContainer from '@components/molecules/form';
import { EnergyTypeEnum } from '@utils/translators/proposal';

import { projectionYears, SubmarketIndexer } from '../form/upsert-prices-projection/helper';

export interface SubmarketSectionProps {
  submarket: SubmarketIndexer;
  description: string;
  energyType: keyof typeof EnergyTypeEnum;
  isRequired: boolean;
  optionalSection: {
    INCENTIVIZED_50: boolean;
    INCENTIVIZED_100: boolean;
  };
}

const SubmarketSection: React.FC<SubmarketSectionProps> = ({ submarket, energyType, description, optionalSection }) => {
  const generateFieldName = (year: number): string => {
    const energyTypeMap: Record<string, string> = {
      INCENTIVIZED_50: 'i5',
      INCENTIVIZED_100: 'i100',
    };

    return `submarkets.${submarket.name}.${energyTypeMap[energyType]}.${
      year.toString().includes('year') ? year.toString() : `year${year}`
    }`;
  };
  return (
    <SubmarketFormContainer title={submarket.label} description={description}>
      <div className="flex h-full gap-5 pb-5 ml-1">
        {projectionYears().map((year, idx) => {
          const fieldName = generateFieldName(year);
          const isRequiredField = optionalSection[energyType] ? false : idx <= 3;
          return (
            <Input.Root key={idx}>
              <Input.Label
                content={year.toString()}
                className="text-paragraph-small font-normal"
                required={isRequiredField}
              />
              <Input.NumericContent
                id={fieldName}
                fieldName={fieldName}
                className="max-w-[8.625rem]"
                required={isRequiredField}
                formatProps={{
                  placeholder: 'R$ 00,00',
                  prefix: 'R$ ',
                  allowNegative: false,
                  fixedDecimalScale: true,
                  decimalScale: 2,
                  suffix: '',
                  thousandSeparator: '.',
                  decimalSeparator: ',',
                  valueIsNumericString: false,
                }}
                rules={{
                  required: { value: isRequiredField, message: 'Esse campo é obrigatório' },
                }}
              />
            </Input.Root>
          );
        })}
      </div>
    </SubmarketFormContainer>
  );
};

export default SubmarketSection;
