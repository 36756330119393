import React, { useEffect } from 'react';

import { useAuth } from '@src/auth-wrapper';
import LoadingPage from '@components/templates/loading-page';

interface PrivateRouteProps {
  children: JSX.Element;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const {
    setIsPrivateRoute,
    authStatus: { accessToken },
  } = useAuth();

  useEffect(() => {
    setIsPrivateRoute(true);
  }, []);
  if (accessToken) {
    return children;
  } else return <LoadingPage></LoadingPage>;
};
